import mirador from 'mirador/dist/es/src/index';
import annotationPlugins from '../../src';
import RerumAdapter from '../../src/RerumAdapter';

const config = {
  annotation: {
    adapter: (canvasId) => new RerumAdapter(canvasId),
    exportLocalStorageAnnotations: false, // display annotation JSON export button
  },
  id: 'demo',
  window: {
    defaultSideBarPanel: 'annotations',
    sideBarOpenByDefault: true,
  },
  windows: [{
    loadedManifest: 'https://static.t-pen.org/fixtures/simple-project/manifest.json',
  }],
};

mirador.viewer(config, [...annotationPlugins]);
